import React from "react";
import LazyLoad from "react-lazyload";
import { graphql, Link } from "gatsby";
import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { SectionLayout } from "~components/templates/section-layout";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import { Button } from "~components/utils/button";
import { Interview } from "~components/utils/interview";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import "./index.scss";
import { AssessmentArticleList } from "~components/articles/assessment-article-list";
import { DocumentStyle } from "~components/templates/document-style";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { TableStyle } from "~components/templates/table-style";

type Props = {
  data: GatsbyAssessmentSpGamePageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <Assessment2PaneLayout
      formId={CONTACT_FORM_ID}
      meta={{
        title: "スマートフォンゲーム診断",
        description:
          "iOSもしくはAndroidが搭載されたスマートフォンで利用するネイティブアプリケーションゲームにメモリ改ざんやガチャの不正操作など脆弱性がないか網羅的に専門のセキュリティエンジニアが診断します。（Webアプリケーションであるブラウザゲームももちろん診断可能です。）",
      }}
    >
      <div className="SpGame">
        <LazyLoad>
          <div className="AssessmentCommonTop SpGameTop">
            <div className="AssessmentCommonTop_Wrapper">
              <SectionLayout>
                <h1 className="AssessmentCommonTop_Title">
                  スマートフォンゲーム診断
                </h1>
                <div className="AssessmentCommonTop_Body">
                  iOSもしくはAndroidが搭載されたスマートフォンで利用するネイティブアプリケーションゲームにメモリ改ざんやガチャの不正操作など脆弱性がないか網羅的に専門のセキュリティエンジニアが診断します。（Webアプリケーションであるブラウザゲームももちろん診断可能です。）
                </div>
              </SectionLayout>
            </div>
          </div>
        </LazyLoad>
        <BreadCrumbs title="スマートフォンゲーム診断" />
        <LazyLoad>
          <div className="Process">
            <div className="Process_Wrapper">
              <h2 className="Process_Heading">診断の手順</h2>
              <ul className="Process_StepList">
                <li className="Process_StepWrapper">
                  <div className="Process_Step">
                    <h3 className="Process_Number">1</h3>
                    <div className="Process_Container">
                      <h2 className="Process_SubHeading">診断準備</h2>
                      <div className="Process_Note note">
                        脆弱性診断の概要を説明した後、NDA締結と事前ヒアリングを行い最適な診断プランをご提案いたします。その後診断対象の本番環境とは別の開発環境を用意していただき、そこでのリクエスト数/IP数を調査しお見積もりを決定します。お見積金額をご確認いただいた上で納期や診断に関するルールを決定し、契約を締結します。
                      </div>
                    </div>
                  </div>

                  <img
                    className="Process_Triangle"
                    src={data?.triangle?.publicURL || ""}
                    alt=""
                  />
                </li>
                <li className="Process_StepWrapper">
                  <div className="Process_Step">
                    <h3 className="Process_Number">2</h3>
                    <div className="Process_Container">
                      <h2 className="Process_SubHeading">診断</h2>
                      <div className="Process_Note note">
                        専門のセキュリティエンジニアが専用のツールと手動での調査を組み合わせ、網羅的に対象を診断します。診断結果は診断完了後に報告書にまとめpdfで納品しますが、特に緊急性の高い脆弱性が検出された場合には当日速報をお出ししてご連絡差し上げます。
                      </div>
                    </div>
                  </div>

                  <img
                    className="Process_Triangle"
                    src={data?.triangle?.publicURL || ""}
                    alt=""
                  />
                </li>
                <li className="Process_StepWrapper">
                  <div className="Process_Step">
                    <h3 className="Process_Number">3</h3>
                    <div className="Process_Container">
                      <h2 className="Process_SubHeading">検収</h2>
                      <div className="Process_Note note">
                        報告書には脆弱性の概要、再現方法、対策方法や参考情報など詳しく記載いたしますので、是非改修にお役立てください。
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </LazyLoad>
        <LazyLoad>
          <div className="Item">
            <Common2PaneSectionLayout>
              <DocumentStyle>
                <h2>診断項目</h2>
                <p>
                  IPAやその他諸々の基準を元に作成した、Flatt
                  Security独自の項目で診断いたします。
                </p>
              </DocumentStyle>
            </Common2PaneSectionLayout>
            <div className="Item_TableWrapper">
              <div className="Item_TableGroup">
                <TableStyle>
                  <table>
                    <tbody>
                      <tr>
                        <th>モバイルゲーム全般に考えられる脆弱性</th>
                        <td>
                          公開鍵ピンニング
                          <br />
                          <br />
                          メモリ改ざん対策
                          <br />
                          <br />
                          アセット/マスタデータの改ざん検知
                          <br />
                          <br />
                          ガチャ課金対策
                          <br />
                          <br /> 権限管理
                          <br />
                          <br />
                          フレンド機能の不備
                          <br />
                          <br />
                          プレゼントボックスの不正操作
                          <br />
                          <br />
                          APIサーバの認証方法について
                          <br />
                          <br />
                          脱獄検知
                          <br />
                          <br />
                          manifestファイルの設定不備
                          <br />
                          <br />
                          WebViewを使用する際の脆弱性
                          <br />
                          <br />
                          耐タンパー性の不足
                          <br />
                          <br />
                          アプリへの秘密情報埋め込み
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TableStyle>
              </div>
              <div className="Item_TableGroup">
                <TableStyle>
                  <table>
                    <tbody>
                      <tr>
                        <th>ゲームの仕様により存在する可能性のある脆弱性</th>
                        <td>
                          その他のガチャ関連操作
                          <br />
                          <br />
                          仮想通貨の不正利用/消費せずにアイテム等を購入
                          <br />
                          <br />
                          アイテムを消費せずに使用
                          <br />
                          <br />
                          キャラクターを不正に強化
                          <br />
                          <br />
                          勝利/クリア条件無視
                          <br />
                          <br />
                          ランキングの不正操作
                          <br />
                          <br />
                          クエスト報酬の不正取得
                          <br />
                          <br />
                          ログインボーナス不正取得
                          <br />
                          <br />
                          機種変更機能のSNS連携等不備
                          <br />
                          <br />
                          友達招待スタミナの不正回復
                          <br />
                          <br />
                          トレードの不正利用
                          <br />
                          <br />
                          ストーリーを不正に閲覧
                          <br />
                          <br />
                          パーティ編成を不正に変更
                          <br />
                          <br />
                          各種スロットの不正増加
                          <br />
                          <br />
                          マルチプレイの不正
                          <br />
                          <br />
                          装備の不正変更
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TableStyle>
              </div>
            </div>
          </div>
        </LazyLoad>
        <LazyLoad>
          <Interview title="スマートフォンゲーム診断の事例インタビュー">
            <AssessmentVoiceList slugs={["mynet"]} />
          </Interview>
        </LazyLoad>
        <LazyLoad>
          <div className="Fee">
            <h2 className="Fee_Header">料金</h2>
            <div className="Fee_Wrapper wow fadeInUp">
              <h4 className="Fee_Title">スマートフォンゲーム診断</h4>
              <h2 className="Fee_Price">
                30
                <span className="Fee_SmallText">万円から</span>
              </h2>
            </div>
            <div className="Fee_Body body">
              リクエストに対して単価が定められている他、クライアント側の機能などもお調べし最終的なリクエスト数・料金を決定します。
            </div>
            <div className="Fee_RequestWrapper">
              <h2 className="Fee_RequestH2">リクエストとは？</h2>
              <div className="Fee_RequestNote note">
                ボタンを押してページが変わったり、入力が確定するなどしてサーバーとの通信が発生することをリクエストと言います。私たちは、リクエスト数、つまりサイト内で通信が起こりうる箇所の数を基準にお見積もりしています。お見積もりは無料でさせていただきますが、リクエスト数＝画面遷移数で計算しても概算が可能です。
              </div>
              <ul className="Fee_RequestExampleWrapper">
                <li className="Fee_RequestExample wow fadeInUp">
                  <h5 className="Fee_RequestH5">
                    例1.
                    <span className="Fee_RequestPrimary">画面遷移する際</span>
                  </h5>
                  <div className="Fee_RequestImageWrapper">
                    <img
                      className="Fee_RequestImage"
                      src={data?.request_1?.publicURL || ""}
                      alt=""
                    />
                  </div>
                  <div className="Fee_RequestExampleNote note">
                    基本的に、ページ遷移のたびにリクエストが発生するので大まかなリクエスト数は計算することができます
                  </div>
                </li>
                <li className="Fee_RequestExample wow fadeInUp">
                  <h5 className="Fee_RequestH5">
                    例2.
                    <span className="Fee_RequestPrimary">
                      画面遷移はしないが通信結果を表示する際
                    </span>
                  </h5>
                  <div className="Fee_RequestImageWrapper">
                    <img
                      className="Fee_RequestImage"
                      src={data?.request_2?.publicURL || ""}
                      alt=""
                    />
                  </div>
                  <div className="Fee_RequestExampleNote note">
                    コメントや検索など、一見ページ遷移が行われていなくてもリクエストが発生するパターンもあります
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </LazyLoad>

        <LazyLoad>
          <AssessmentArticleList
            data={{
              articles: data.allContentfulArticle.nodes,
              category: data.contentfulArticleCategory,
            }}
          />
        </LazyLoad>
        <h2 className="AssessmentCommonContact_Title">
          お見積もりは無料です。診断のご依頼はお気軽にお問い合わせください。
        </h2>
        <Link to="/contact">
          <div className="AssessmentCommonQuickContact">
            <Button buttonTheme="Secondary" buttonSize="Large">
              今すぐお問い合わせ
            </Button>
          </div>
        </Link>
      </div>
    </Assessment2PaneLayout>
  );
};

export const query = graphql`
  query AssessmentSpGamePage {
    checkbox: file(relativePath: { eq: "checkbox.png" }) {
      publicURL
    }
    document: file(relativePath: { eq: "document.png" }) {
      publicURL
    }
    request_1: file(relativePath: { eq: "request_1.png" }) {
      publicURL
    }
    request_2: file(relativePath: { eq: "request_2.png" }) {
      publicURL
    }
    triangle: file(relativePath: { eq: "assessment/triangle.png" }) {
      publicURL
    }
    allContentfulArticle(
      filter: {
        category: { slug: { eq: "sp_game" } }
        slug: { ne: "disabled" }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...AssessmentArticleListItemData
      }
    }
    contentfulArticleCategory(slug: { eq: "sp_game" }) {
      ...AssessmentArticlaListCategory
    }
  }
`;

export default withContext(Component);
